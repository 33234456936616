import { b2x } from '@b2x/react/src';
import React from 'react';

import { Accordion } from '../Accordion';
import { Button } from '../Button';

export interface MasterclassMailUpFormProps {}

interface FormValues {
  campo1?: b2x.formikString;
  campo10?: b2x.formikString;
  campo13?: b2x.formikString;
  campo14?: b2x.formikString;
  campo15?: b2x.formikString;
  campo16?: b2x.formikString;
  campo18?: b2x.formikString;
  campo19?: b2x.formikString;
  campo2?: b2x.formikString;
  campo20?: b2x.formikString;
  campo21?: b2x.formikString;
  campo22?: b2x.formikString;
  campo23?: b2x.formikString;
  campo24?: b2x.formikString;
  campo25?: b2x.formikString;
  campo26?: b2x.formikString;
  campo3?: b2x.formikBoolean;
  campo30?: b2x.formikString;
  campo31?: b2x.formikDate;
  campo33?: b2x.formikString;
  campo34?: b2x.formikString;
  campo35?: b2x.formikString;
  campo36?: b2x.formikString;
  campo37?: b2x.formikString;
  campo38?: b2x.formikString;
  campo39?: b2x.formikString;
  campo4?: b2x.formikString;
  campo5?: b2x.formikString;
  campo6?: b2x.formikString;
  campo7?: b2x.formikBoolean;
  campo8?: b2x.formikBoolean;
  campo9?: b2x.formikString;
  email?: b2x.formikString;
  number?: b2x.formikString;
}

type ValidationSchema = {
  campo1?: b2x.yup.StringSchema;
  campo10?: b2x.yup.StringSchema;
  campo13?: b2x.yup.StringSchema;
  campo14?: b2x.yup.StringSchema;
  campo15?: b2x.yup.StringSchema;
  campo16?: b2x.yup.StringSchema;
  campo18?: b2x.yup.StringSchema;
  campo19?: b2x.yup.StringSchema;
  campo2?: b2x.yup.StringSchema;
  campo20?: b2x.yup.StringSchema;
  campo21?: b2x.yup.StringSchema;
  campo22?: b2x.yup.StringSchema;
  campo23?: b2x.yup.StringSchema;
  campo24?: b2x.yup.StringSchema;
  campo25?: b2x.yup.StringSchema;
  campo26?: b2x.yup.StringSchema;
  campo3?: b2x.yup.BooleanSchema;
  campo30?: b2x.yup.StringSchema;
  campo31?: b2x.yup.DateSchema;
  campo33?: b2x.yup.StringSchema;
  campo34?: b2x.yup.StringSchema;
  campo35?: b2x.yup.StringSchema;
  campo36?: b2x.yup.StringSchema;
  campo37?: b2x.yup.StringSchema;
  campo38?: b2x.yup.StringSchema;
  campo39?: b2x.yup.StringSchema;
  campo4?: b2x.yup.StringSchema;
  campo5?: b2x.yup.StringSchema;
  campo6?: b2x.yup.StringSchema;
  campo7?: b2x.yup.BooleanSchema;
  campo8?: b2x.yup.BooleanSchema;
  campo9?: b2x.yup.StringSchema;
  email?: b2x.yup.StringSchema;
  number?: b2x.yup.StringSchema;
};

export const MasterclassMailUpForm = (props: MasterclassMailUpFormProps) => {
  const mailUpFormRef = React.useRef<HTMLFormElement>(null);

  const initialValues = React.useMemo<FormValues>(
    () => ({
      campo1: b2x.getInitialString(),
      campo10: b2x.getInitialString(),
      campo13: b2x.getInitialString(),
      campo14: b2x.getInitialString(),
      campo15: b2x.getInitialString(),
      campo16: b2x.getInitialString(),
      campo18: b2x.getInitialString(),
      campo19: b2x.getInitialString(),
      campo2: b2x.getInitialString(),
      campo20: b2x.getInitialString(),
      campo21: b2x.getInitialString(),
      campo22: b2x.getInitialString(),
      campo23: b2x.getInitialString(),
      campo24: b2x.getInitialString(),
      campo25: b2x.getInitialString(),
      campo26: b2x.getInitialString(),
      campo3: b2x.getInitialBoolean(),
      campo30: b2x.getInitialString(),
      campo31: b2x.getInitialDate(),
      campo33: b2x.getInitialString(),
      campo34: b2x.getInitialString(),
      campo35: b2x.getInitialString(),
      campo36: b2x.getInitialString(),
      campo37: b2x.getInitialString(),
      campo38: b2x.getInitialString(),
      campo39: b2x.getInitialString(),
      campo4: b2x.getInitialString(),
      campo5: b2x.getInitialString(),
      campo6: b2x.getInitialString(),
      campo7: b2x.getInitialBoolean(),
      campo8: b2x.getInitialBoolean(),
      campo9: b2x.getInitialString(),
      email: b2x.getInitialString(),
      number: b2x.getInitialString(),
    }),
    []
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      campo1: b2x.yup.string().required(),
      campo10: b2x.yup.string().required(),
      campo13: b2x.yup.string().required(),
      campo14: b2x.yup.string().required(),
      campo15: b2x.yup.string(),
      campo16: b2x.yup.string().required(),
      campo18: b2x.yup.string().required(),
      campo19: b2x.yup.string().required(),
      campo2: b2x.yup.string().required(),
      campo20: b2x.yup.string(),
      campo21: b2x.yup.string().required(),
      campo22: b2x.yup.string(),
      campo23: b2x.yup.string(),
      campo24: b2x.yup.string(),
      campo25: b2x.yup.string(),
      campo26: b2x.yup.string(),
      campo3: b2x.yup.boolean().required().oneOf([true]),
      campo30: b2x.yup.string().required(),
      campo31: b2x.yup.date().required(),
      campo33: b2x.yup.string().required(),
      campo34: b2x.yup.string().required(),
      campo35: b2x.yup.string().required(),
      campo36: b2x.yup.string().required(),
      campo37: b2x.yup.string().required(),
      campo38: b2x.yup.string().required(),
      campo39: b2x.yup.string().required(),
      campo4: b2x.yup.string().required(),
      campo5: b2x.yup.string().required(),
      campo6: b2x.yup.string().required(),
      campo7: b2x.yup.boolean().required().oneOf([true]),
      campo8: b2x.yup.boolean().required().oneOf([true]),
      campo9: b2x.yup.string().required(),
      email: b2x.yup.string().required().email(),
      number: b2x.yup.string().required(),
    }),
    []
  );

  const handleSubmit = React.useCallback((values: FormValues) => {
    return Promise.resolve().then(() => {
      mailUpFormRef.current?.submit();
    });
  }, []);

  return (
    <b2x.Form<FormValues, ValidationSchema>
      action="https://a6f6b3.emailsp.com/Frontend/subscribe.aspx"
      initialValues={initialValues}
      innerRef={mailUpFormRef}
      method="POST"
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <input name="apgroup" type="hidden" value="25" />
      <input name="list" type="hidden" value="2" />
      <b2x.Row>
        <b2x.Col size={12}>
          <b2x.FormGroup label={'Edizione a cui si vuole partecipare'} names={['campo18']}>
            <b2x.Select includeEmptyOption={false} name="campo18" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="29 aprile – 5 maggio 2024" value="29 aprile – 5 maggio 2024" />
              {/* <b2x.Option label="Summer Edition (luglio/agosto 2024)" value="Summer Edition (luglio/agosto 2024)" /> */}
              <b2x.Option
                label="8-14 luglio Centro Equestre S.Giorgio (RM)"
                value="8-14 luglio Centro Equestre S.Giorgio (RM)"
              />
              <b2x.Option label="Christmas Edition (dicembre 2024)" value="Christmas Edition (dicembre 2024)" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Nome Atleta'} names={['campo1']}>
            <b2x.TextInput name="campo1" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Cognome Atleta'} names={['campo2']}>
            <b2x.TextInput name="campo2" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Luogo di nascita'} names={['campo30']}>
            <b2x.TextInput name="campo30" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Data di nascita'} names={['campo31']}>
            <b2x.DateInput name="campo31" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Indirizzo e CAP'} names={['campo9']}>
            <b2x.TextInput name="campo9" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Città e Provincia'} names={['campo4']}>
            <b2x.TextInput name="campo4" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Telefono'} names={['number']}>
            <b2x.TextInput name="number" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Email'} names={['email']}>
            <b2x.TextInput name="email" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Codice Fiscale'} names={['campo33']}>
            <b2x.TextInput name="campo33" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Patente FISE'} names={['campo34']}>
            <b2x.Select includeEmptyOption={false} name="campo34" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="BREV" value="BREV" />
              <b2x.Option label="I G" value="I G" />
              <b2x.Option label="II G" value="II G" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={12}>
          <b2x.FormGroup label={'Centro Ippico di Appartenenza'} names={['campo35']}>
            <b2x.TextInput name="campo35" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Nome istruttore'} names={['campo37']}>
            <b2x.TextInput name="campo37" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Nome cavallo'} names={['campo38']}>
            <b2x.TextInput name="campo38" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={12}>
          <Accordion
            className="mt-5 mb-4"
            id="accordion-masterclass"
            itemBodyClassName="px-0"
            itemButtonClassName="bg-transparent px-0 fw-semi-bold"
            itemClassName="bg-transparent"
          >
            <b2x.AccordionItem id="masterclass-junior" title="Sei un minore?">
              <b2x.Row>
                <b2x.Col size={{ lg: 6, xs: 12 }}>
                  <b2x.FormGroup label={'Nome e Cognome Genitore'} names={['campo15']}>
                    <b2x.TextInput name="campo15" />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={{ lg: 6, xs: 12 }}>
                  <b2x.FormGroup label={'Indirizzo e CAP genitore'} names={['campo22']}>
                    <b2x.TextInput name="campo22" />
                  </b2x.FormGroup>
                </b2x.Col>
              </b2x.Row>
              <b2x.Row>
                <b2x.Col size={{ lg: 6, xs: 12 }}>
                  <b2x.FormGroup label={'Città e Provincia del genitore'} names={['campo25']}>
                    <b2x.TextInput name="campo25" />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={{ lg: 6, xs: 12 }}>
                  <b2x.FormGroup label={'Numero di telefono del genitore'} names={['campo26']}>
                    <b2x.TextInput name="campo26" />
                  </b2x.FormGroup>
                </b2x.Col>
              </b2x.Row>
              <b2x.Row>
                <b2x.Col size={{ lg: 6, xs: 12 }}>
                  <b2x.FormGroup label={'Email genitore'} names={['campo24']}>
                    <b2x.TextInput name="campo24" />
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={{ lg: 6, xs: 12 }}>
                  <b2x.FormGroup label={'CF genitore'} names={['campo23']}>
                    <b2x.TextInput name="campo23" />
                  </b2x.FormGroup>
                </b2x.Col>
              </b2x.Row>
              <b2x.Row>
                <b2x.Col size={12}>
                  <b2x.FormGroup label={'Note'} names={['campo20']}>
                    <b2x.TextArea name="campo20" rows={4} />
                  </b2x.FormGroup>
                </b2x.Col>
              </b2x.Row>
            </b2x.AccordionItem>
          </Accordion>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={12}>
          <h3 className="h5 fw-semi-bold mb-3">Misure abbigliamento</h3>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Felpa'} names={['campo10']}>
            <b2x.Select includeEmptyOption={false} name="campo10" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="XS" value="XS" />
              <b2x.Option label="S" value="S" />
              <b2x.Option label="M" value="M" />
              <b2x.Option label="L" value="L" />
              <b2x.Option label="XL" value="XL" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'T-Shirt'} names={['campo39']}>
            <b2x.Select includeEmptyOption={false} name="campo39" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="XS" value="XS" />
              <b2x.Option label="S" value="S" />
              <b2x.Option label="M" value="M" />
              <b2x.Option label="L" value="L" />
              <b2x.Option label="XL" value="XL" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Giacca Concorso'} names={['campo14']}>
            <b2x.Select includeEmptyOption={false} name="campo14" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="XS" value="XS" />
              <b2x.Option label="S" value="S" />
              <b2x.Option label="M" value="M" />
              <b2x.Option label="L" value="L" />
              <b2x.Option label="XL" value="XL" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Cap'} names={['campo36']}>
            <b2x.Select includeEmptyOption={false} name="campo36" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="S (53 - 55 cm)" value="S (53 - 55 cm)" />
              <b2x.Option label="M (56 - 58 cm)" value="M (56 - 58 cm)" />
              <b2x.Option label="L (59 - 61 cm)" value="L (59 - 61 cm)" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Pantaloni'} names={['campo13']}>
            <b2x.Select includeEmptyOption={false} name="campo13" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="36" value="36" />
              <b2x.Option label="38" value="38" />
              <b2x.Option label="40" value="40" />
              <b2x.Option label="42" value="42" />
              <b2x.Option label="44" value="44" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Cavezza / Testiera'} names={['campo21']}>
            <b2x.Select includeEmptyOption={false} name="campo21" placeholder="Seleziona una delle opzioni">
              <b2x.Option label="PONY" value="PONY" />
              <b2x.Option label="COB" value="COB" />
              <b2x.Option label="FULL" value="FULL" />
            </b2x.Select>
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Stivali - Taglia Piede'} names={['campo5']}>
            <b2x.TextInput name="campo5" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Stivali - Altezza (cm)'} names={['campo16']}>
            <b2x.TextInput name="campo16" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Stivali - Polpaccio (cm)'} names={['campo6']}>
            <b2x.TextInput name="campo6" />
          </b2x.FormGroup>
        </b2x.Col>
        <b2x.Col size={{ lg: 6, xs: 12 }}>
          <b2x.FormGroup label={'Stivali - Caviglia (cm)'} names={['campo19']}>
            <b2x.TextInput name="campo19" />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={12}>
          <b2x.FormGroup label={undefined} names={['campo3']}>
            <b2x.Checkbox
              id="campo3"
              label="Ai sensi del D. Lgs 30 giugno 2003 n.196, si dichiara di essere stati informati e di prestare il consenso al trattamento dei dati personali ai fini dell'erogazione del servizio"
              name="campo3"
            />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={12}>
          <b2x.FormGroup label={undefined} names={['campo7']}>
            <b2x.Checkbox
              id="campo7"
              label="Ai sensi del D. Lgs 30 giugno 2003 n.196, si dichiara di essere stati informati e di prestare il consenso all'inserimento del nostro nominativo nella banca dati di AG Srl al fine di ricevere nuove informative sulle attività dell'azienda, con l'esclusione della comunicazione dello stesso a terzi per altri motivi."
              name="campo7"
            />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col size={12}>
          <b2x.FormGroup label={undefined} names={['campo8']}>
            <b2x.Checkbox
              id="campo8"
              label="Dichiara inoltre di accettare il REGOLAMENTO di Campus Master Class allegato alla iscrizione e consultabile sul sito web www.campusmasterclass.com"
              name="campo8"
            />
          </b2x.FormGroup>
        </b2x.Col>
      </b2x.Row>
      <b2x.Row>
        <b2x.Col className="d-grid d-sm-block text-center" size={12}>
          <Button className="mt-4" label={'Iscriviti'} type="submit" />
        </b2x.Col>
      </b2x.Row>
    </b2x.Form>
  );
};
