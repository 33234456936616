import './Accordion.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface AccordionProps extends b2x.AccordionProps {
  variant?: 'b';
}

export const Accordion = ({ className, variant, ...otherProps }: AccordionProps) => {
  return (
    <b2x.Accordion {...otherProps} className={classnames(className, { 'variant-b': variant === 'b' })}></b2x.Accordion>
  );
};
