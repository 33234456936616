import './SectionA.scss';

import { b2x } from '@b2x/react/src';

import { BlockQuote } from './BlockQuote';
import { SectionAContentSection } from './contentTypes';
import { Section } from './Section';

export interface SectionAProps extends SectionAContentSection {}

export const SectionA = ({ content, hideIconQuote, titleA, titleB, titleC }: SectionAProps): React.ReactElement => {
  return (
    <div className="SectionA">
      <Section className="py-3 mt-4 py-xl-5 mt-xl-5" container="xxl">
        <div className="row">
          <div className="col-lg-5 mb-3 mb-lg-0 text-uppercase">
            <h4 className="my-0 h1 fw-light">{titleA}</h4>
            <h3 className="my-0 h1 fw-semi-bold">{titleB}</h3>
            <h3 className="my-0 h1 fw-semi-bold text-primary">{titleC}</h3>
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <BlockQuote hideIconQuote={hideIconQuote} size="h3">
              {b2x.formatHtml(content)}
            </BlockQuote>
          </div>
        </div>
      </Section>
    </div>
  );
};
