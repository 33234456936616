import './BlockQuote.scss';

import classnames from 'classnames';

import { Icon } from './Icon';

export interface BlockQuoteProps {
  author?: string;
  children?: React.ReactNode;
  hideIconQuote?: boolean;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  textCenter?: boolean;
}

export const BlockQuote = ({
  author,
  children,
  hideIconQuote = false,
  size,
  textCenter,
}: BlockQuoteProps): React.ReactElement => {
  return (
    <div className={classnames('BlockQuote', { 'text-center': textCenter })}>
      {!hideIconQuote && <Icon name="quote-sx" size={48} />}
      <span className={`fw-light ${size}`}>{children}</span>
      {!hideIconQuote && <Icon name="quote-dx" size={48} />}
      {author && <div className="text-primary mt-3 h5 fw-bold">{author}</div>}
    </div>
  );
};
