import { b2x } from '@b2x/react/src';

import { Container } from './Container';
import { SearchForm } from './forms/SearchForm';
import { Loading } from './Loading';

export interface SearchSectionProps {
  fetching: boolean;
  searchFormProps: b2x.SearchFormProps;
}

export const SearchSection = ({
  fetching,
  searchFormProps: { searchResult, ...otherSearchFormProps },
}: SearchSectionProps) => {
  return (
    <>
      {searchResult === undefined ? (
        <Loading />
      ) : (
        <>
          <SearchForm searchResult={searchResult} {...otherSearchFormProps} />
          <div className="container-fluid">
            {searchResult.items && (
              <b2x.Listing name="Search page" products={searchResult.items}>
                <b2x.ProductsTiles
                  enableExtraInfo
                  populate={b2x.appConfig.api?.productTilePopulate}
                  products={searchResult.items.map((product) => ({ product: product }))}
                  productsPerRow={{ lg: 4, md: 2, sm: 2, xl: 4, xs: 2 }}
                />
              </b2x.Listing>
            )}
          </div>
          <Container>
            <div className="mb-n3 mb-xl-n5 mt-3 d-flex justify-content-center justify-content-md-end">
              <b2x.Pagination
                currentPage={searchResult.pageNumber}
                pageOffset={2}
                showDots
                singleStepNavigation
                totalPages={searchResult.pagesCount}
              />
            </div>
          </Container>
        </>
      )}
    </>
  );
};
