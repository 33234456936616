import './TopSlider.scss';

import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { HeaderContentType } from '../contentTypes';
import { Slider } from '../Slider';
export interface TopSliderProps extends HeaderContentType {}

export const TopSlider = ({ messagesList }: TopSliderProps) => {
  return (
    <b2x.EqualHeight>
      <Slider
        autoHeight
        className="TopSlider"
        fade
        loop
        pagination
        sliderAutoPlay={messagesList && messagesList.length > 1 ? 3000 : undefined}
        slidesPerView={1}
      >
        {messagesList?.map((slide, index) => (
          <SwiperSlide key={slide.title}>
            <b2x.ConditionalWrapper
              condition={slide.url !== undefined && slide.url !== ''}
              wrapper={<b2x.router.Link className="text-reset text-decoration-none" to={slide.url} />}
            >
              <b2x.EqualHeightElement name="top-slider-item">
                <div className="slide-text text-uppercase extra-small lh-sm fw-light">{slide.title}</div>
              </b2x.EqualHeightElement>
            </b2x.ConditionalWrapper>
          </SwiperSlide>
        ))}
      </Slider>
    </b2x.EqualHeight>
  );
};
