import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { Container } from '../Container';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export interface UnsubscribeNewsletterPageProps {}

export const UnsubscribeNewsletterPage = (props: UnsubscribeNewsletterPageProps) => {
  const { state } = b2x.useNewsletterUnsubscriber({ errorIfMissingToken: false });

  return (
    <Page greyBackground>
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-xl-5">
            <Box>
              <h3 className="text-uppercase text-center mb-3">{t('misc.unsubscribeNewsletterByToken.title')}</h3>
              <p className="fw-light mb-4">
                {state === 'pending' && t('misc.unsubscribeNewsletterByToken.pending')}
                {state === 'success' && t('misc.unsubscribeNewsletterByToken.success')}
              </p>
              <div className="d-grid">
                <b2x.router.Link className="btn btn-primary" to="/">
                  Ok
                </b2x.router.Link>
              </div>
            </Box>
          </div>
        </div>
      </Container>
    </Page>
  );
};
