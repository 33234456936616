import './NavigationListingToolbar.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { Dropdown } from './Dropdown';
import { t } from './i18n/i18n';

export interface NavigationListingToolbarProps {
  currentFiltersItems?: Array<CurrentFiltersItem>;
  formik?: b2x.FormikProps<b2x.SearchFormValues>;
  navigationItems?: Array<NavigationItem>;
  showMobileSearchFiltersOffcanvas: React.MouseEventHandler<HTMLButtonElement>;
}

interface NavigationItem {
  content?: Array<AvailableFiltersGroup>;
  label?: string;
}

interface AvailableFiltersGroup {
  checkboxes?: Array<{ checkbox: b2x.CheckboxProps; open: boolean; subCheckboxes?: Array<b2x.CheckboxProps> }>;
  label?: string;
}

// interface AvailableFiltersItem {
//   label: string;
// }

interface CurrentFiltersItem {
  fromSimpleSearch: boolean;
  label: string;
  onClick(): void;
}

export const NavigationListingToolbar = ({
  currentFiltersItems,
  formik,
  navigationItems,
  showMobileSearchFiltersOffcanvas,
}: NavigationListingToolbarProps) => {
  const [navigationItemIndexActive, setNavigationItemIndexActive] = React.useState<number>();

  const close = React.useCallback(() => {
    setNavigationItemIndexActive(undefined);
  }, []);

  const ref = React.useRef<HTMLDivElement>(null);

  b2x.useOutsideClickHandler(ref, close);
  b2x.useKeyPress('Escape', close);

  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);
  return (
    <div className="NavigationListingToolbar bg-white">
      <div className="listing-navigation-available border-bottom py-3 mb-3 d-none d-lg-block">
        <div className="container-xxl">
          <div className="navigation-prop-container">
            {navigationItems?.map(
              (navigationItem, index) =>
                navigationItem.content &&
                navigationItem.content.length > 0 && (
                  <div className="btn-wrapper" key={navigationItem.label}>
                    <Button
                      alignItems="center"
                      alignWithFlex
                      className={classnames(
                        'btn-sm extra-small',
                        navigationItemIndexActive === index ? 'fw-bold' : 'fw-light'
                      )}
                      iconEnd={{ className: 'ms-5', name: 'small-arrow-down', size: 12 }}
                      justifyContent="between"
                      label={navigationItem.label}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        setNavigationItemIndexActive(index);
                      }}
                      type="button"
                      variant="outline-link"
                    />
                  </div>
                )
            )}
            <div className="btn-wrapper">
              <Dropdown alignment="left" label={t('misc.orderBy')} variant="outline-link">
                {sortingOptions.map((sortingOption) => (
                  <b2x.DropdownItem
                    active={sortingOption.active}
                    key={sortingOption.label}
                    onClick={sortingOption.onClick}
                  >
                    <Button className="btn-sm fw-light" variant="outline-link">
                      {sortingOption.label}
                    </Button>
                  </b2x.DropdownItem>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>
        {navigationItemIndexActive !== undefined && navigationItems && (
          <div className="listing-navigation-dropdown bg-white shadow-sm pt-3 pb-5" ref={ref}>
            <div className="container-xxl">
              <div className="row justify-content-start">
                {navigationItems[navigationItemIndexActive].content?.map((availableFiltersGroup) => {
                  const activeNavigationItem = navigationItems[navigationItemIndexActive];
                  return (
                    <div
                      className={classnames(
                        activeNavigationItem.content !== undefined && activeNavigationItem.content.length > 2
                          ? 'col'
                          : 'col-auto',
                        'p-4'
                      )}
                      key={availableFiltersGroup.label}
                    >
                      <p className="text-uppercase fw-light text-dark">{availableFiltersGroup.label}</p>
                      <div>
                        {availableFiltersGroup.checkboxes?.map((checkbox, index) => (
                          <div className="filter-item fw-light small text-dark" key={checkbox.checkbox.id}>
                            {/* <AvailableFilterButton
                              label={availableFilterItem.label}
                              value={`${navigationItemActive.label} - ${availableFiltersGroup.label} - ${availableFilterItem.label}`}
                            /> */}
                            {checkbox.subCheckboxes && checkbox.subCheckboxes.length > 0 ? (
                              <CheckboxAsAccordion checkbox={checkbox} />
                            ) : (
                              <b2x.Checkbox {...checkbox.checkbox} />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-lg-none mb-2">
        <div className="container-xxl">
          <div className="text-center">
            <Button
              alignItems="center"
              alignWithFlex
              className="extra-small fw-light"
              iconStart={{ name: 'filter', size: 25 }}
              label={'Filtra e ordina per'}
              onClick={showMobileSearchFiltersOffcanvas}
              type={'button'}
              variant={'outline-link'}
            />
          </div>
        </div>
      </div>
      {currentFiltersItems && currentFiltersItems.length > 0 && (
        <div className="current-filter mb-3">
          <div className="container-xxl">
            <div className="d-flex flex-wrap gap-3">
              {currentFiltersItems.map((currentFilterItem) => (
                <React.Fragment key={currentFilterItem.label}>
                  {currentFilterItem.fromSimpleSearch && (
                    <span className="py-1 fw-light small">{t('misc.youSearchedFor')}</span>
                  )}
                  <CurrentFilterButton label={currentFilterItem.label} onClick={currentFilterItem.onClick} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// interface AvailableFilterButtonProps {
//   label: string;
//   value: string;
// }

// const AvailableFilterButton = ({ label, value }: AvailableFilterButtonProps) => {
//   const handleAvailableFilterButtonClick = useCallback(() => {
//     alert(`hai selezionato l'opzione  ${value}`);
//   }, [value]);
//   return (
//     <Button
//       className="fw-light btn-sm text-capitalize text-uppercase"
//       label={`[ ] ${label}`}
//       onClick={handleAvailableFilterButtonClick}
//       variant="outline-link"
//     />
//   );
// };

interface CurrentFilterButtonProps {
  label: string;
  onClick(): void;
}

const CurrentFilterButton = ({ label, onClick }: CurrentFilterButtonProps) => {
  return (
    <Button
      className="fw-light btn-sm text-capitalize py-1 px-3"
      iconEnd={{ className: 'ms-4', name: 'delete', size: 14 }}
      label={`${label}`}
      onClick={onClick}
      type="button"
      variant="lighter"
    />
  );
};

interface CheckboxAsAccordionProps {
  checkbox: { checkbox: b2x.CheckboxProps; open: boolean; subCheckboxes?: Array<b2x.CheckboxProps> };
}

const CheckboxAsAccordion = ({ checkbox }: CheckboxAsAccordionProps) => {
  const [openSubfilter, setOpenSubfilter] = React.useState<boolean>(checkbox.open);

  const handleButtonClick = React.useCallback(() => {
    if (openSubfilter) {
      setOpenSubfilter(false);
    } else {
      setOpenSubfilter(true);
    }
  }, [openSubfilter]);

  return (
    <>
      {checkbox.subCheckboxes && checkbox.subCheckboxes.length > 0 && (
        <>
          <div className="filter-with-children d-grid">
            <Button
              className={classnames('p-0', { 'fw-light': !checkbox.open })}
              iconEnd={openSubfilter ? { name: 'small-arrow-up', size: 12 } : { name: 'small-arrow-down', size: 12 }}
              justifyContent="between"
              label={checkbox.checkbox.label}
              onClick={handleButtonClick}
              variant="outline-link"
            />
          </div>
          <div className={classnames('filter-item-options ms-4 py-2', { 'd-none': !openSubfilter })}>
            {checkbox.subCheckboxes.map((subCheckbox) => (
              <b2x.Checkbox key={subCheckbox.id} {...subCheckbox} />
            ))}
            <b2x.Checkbox {...checkbox.checkbox} label="Tutti" />
          </div>
        </>
      )}
    </>
  );
};
