import { b2x } from '@b2x/react/src';
import React from 'react';

import { HomePageContentType } from '../contentTypes';
import { Section } from '../Section';
import { SectionA } from '../SectionA';
import { SliderA } from '../slider/SliderA';
import { SliderB } from '../slider/SliderB';
import { SliderC } from '../slider/SliderC';
import { SliderD } from '../slider/SliderD';
import { Page } from './Page';
import { PopUpNewsletterModal } from './PopupNewsletterModal';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const { showModal } = b2x.useModals();
  const { pageReady } = b2x.useAppContext();

  React.useEffect(() => {
    const modalAlreadyShown = b2x.cookies.getBoolean('homePageNewsletterModal');
    if (!modalAlreadyShown && pageReady) {
      b2x.wait(3000).then(() => {
        showModal({
          children: <PopUpNewsletterModal />,
          onClose: () => {
            b2x.cookies.setBoolean('homePageNewsletterModal', true, { expires: 1 });
          },
          size: 'default',
          title: '',
        });
      });
    }
  }, [pageReady, showModal]);

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]} transparentHeader>
      <SliderA alignItems="center" container="xxl" justifyContent="center" {...page?.content?.body.slideshow} />
      <SectionA {...page?.content?.body.sectionQuote} />
      <Section background="lighter" className="mb-3" container="fluid" withDots>
        <SliderB {...page?.content?.body.collectionSlider} />
      </Section>
      <Section className="my-4 my-lg-5 py-3" container="fluid">
        <b2x.Listing name={`Home page - ${page?.content?.body.productSlider?.title}`} products={page?.products}>
          <SliderC {...page?.content?.body.productSlider} products={page?.products} />
        </b2x.Listing>
      </Section>
      <Section background="lighter" className="my-4 my-lg-5 py-5" container="fluid">
        <SliderD {...page?.content?.body.createYourStyleSlider} />
      </Section>
      <p>
        <iframe
          src="https://widget.taggbox.com/134183"
          style={{ border: 'none', height: '600px', width: '100%' }}
          title="taggbox"
        ></iframe>
      </p>
    </Page>
  );
};
