import classnames from 'classnames';

import { Breadcrumb } from './Breadcrumb';
import { Section } from './Section';

export interface PageHeaderProps {
  breadcrumb?: boolean;
  hiddenBreadcrumbLastItem?: boolean;
  small?: boolean;
  subtitle?: string;
  title: string;
}

export const PageHeader = ({ breadcrumb, hiddenBreadcrumbLastItem, small, subtitle, title }: PageHeaderProps) => {
  return (
    <div className="PageHeader">
      <Section className="py-4 py-lg-5 text-center" container="xxl">
        {breadcrumb && <Breadcrumb alignment={'center'} hideLastItem={hiddenBreadcrumbLastItem} />}
        <h1
          className={classnames('text-uppercase m-0', small ? 'h3' : 'dispaly-1 fw-semi-bold', {
            'mb-1': subtitle,
          })}
        >
          {title}
        </h1>
        {subtitle && <p className="text-dark m-0">{subtitle}</p>}
      </Section>
    </div>
  );
};
