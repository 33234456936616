import { Col } from '../Col';
import { Container } from '../Container';
import { Div } from '../HTMLElement';
import { Row } from '../Row';
import { Video } from '../Video';
import { VideoAsBackground } from '../VideoAsBackground';

export const Videos = () => {
  return (
    <Container>
      <Div marginBottom={3}>
        <h3>Responsive</h3>
      </Div>
      <Div marginBottom={3}>
        {"La larghezza si addatta al contenitore, l'altezza è libera e rispetta la proporzione del video."}
      </Div>
      <Div marginBottom={5}>
        <Row cols={3}>
          <Col>
            <h5>bcom</h5>
            <Video
              variants={{
                xs: {
                  height: 1080,
                  source: {
                    bcom: {
                      file: 'https://www.b2x.it/admin/rest/files/2023/08/30/280431.mp4',
                    },
                  },
                  width: 1920,
                },
              }}
            />
          </Col>
          <Col>
            <h5>YouTube</h5>
            <Video
              variants={{
                xs: {
                  height: 1080,
                  source: {
                    youtube: {
                      id: 'mkggXE5e2yk',
                    },
                  },
                  width: 1920,
                },
              }}
            />
          </Col>
          <Col>
            <h5>Vimeo</h5>
            <Video
              variants={{
                xs: {
                  height: 1080,
                  source: {
                    vimeo: {
                      id: '226053498',
                      privacyHash: 'a1599a8ee9',
                    },
                  },
                  width: 1920,
                },
              }}
            />
          </Col>
        </Row>
      </Div>
      <Div marginBottom={3}>
        <h3>As background</h3>
      </Div>
      <Div marginBottom={3}>
        {
          "Il video verrà croppato per copire il contenitore, che può avere qualsiasi dimensione (simula l'object-fit per le immagini)."
        }
      </Div>
      <Row cols={3}>
        <Col>
          <h5>bcom</h5>
          <div style={{ height: 300, position: 'relative', width: '100%' }}>
            <VideoAsBackground
              variants={[
                {
                  height: 2320,
                  source: {
                    bcom: {
                      file: 'https://www.b2x.it/admin/rest/files/2022/09/05/267641.mp4',
                    },
                  },
                  width: 1534,
                },
                {
                  height: 1050,
                  source: {
                    bcom: {
                      file: 'https://www.b2x.it/admin/rest/files/2022/07/21/264221.mp4',
                    },
                  },
                  width: 1982,
                },
              ]}
            />
          </div>
        </Col>
        <Col>
          <h5>YouTube</h5>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: 300,
              justifyContent: 'center',
              position: 'relative',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <h6>
              <strong>Non supportato</strong>
            </h6>
            Non potendo nascondere i controlli, questi verrebbero croppati male.
          </div>
        </Col>
        <Col>
          <h5>Vimeo</h5>
          <div style={{ height: 300, position: 'relative', width: '100%' }}>
            <VideoAsBackground
              variants={[
                {
                  height: 1080,
                  source: {
                    vimeo: {
                      id: '226053498',
                      privacyHash: 'a1599a8ee9',
                    },
                  },
                  width: 1920,
                },
              ]}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
