import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface ListCProps {
  items: Array<{
    label?: string;
    link?: string;
  }>;
  title: string;
}

export const ListC = ({ items, title }: ListCProps) => {
  return (
    <div className="ListC">
      <div className="title fw-semi-bold text-uppercase small py-3 px-4 px-lg-5 bg-primary mb-1">{title}</div>
      <div className="vstack gap-2 bg-white py-3">
        {items.map((item) => (
          <b2x.router.NavLink
            className="text-reset text-decoration-none small py-1 px-4 px-lg-5"
            key={item.label}
            to={item.link}
          >
            {({ isActive }) => (
              <span className={classnames(isActive && 'border-bottom border-primary border-3 py-1')}>{item.label}</span>
            )}
          </b2x.router.NavLink>
        ))}
      </div>
    </div>
  );
};
