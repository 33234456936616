import './SliderG.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Fetching } from '../Fetching';
import { ProductTile } from '../ProductTile';
import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';

export interface SliderGProps {
  products?: Array<b2x.ProductApiDto>;
}

export const SliderG = ({ products }: SliderGProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  return (
    <div className="SliderG">
      <b2x.EqualHeight>
        <div className="slider-container">
          <Fetching item={products}>
            <Slider
              className="product-slider next-slide-3"
              loop
              navigation
              responsive={{
                lg: {
                  slidesPerView: 3.15,
                },
                md: {
                  slidesPerView: 2.15,
                },
                xl: {
                  slidesPerView: 4,
                },
              }}
              sliderNextEl={sliderNextButton}
              sliderPrevEl={sliderPrevButton}
              slidesPerView={1.15}
              spaceBetween={20}
            >
              {products?.map((product, index) => (
                <SwiperSlide key={product.id}>
                  <ProductTile product={product} />
                </SwiperSlide>
              ))}
            </Slider>
          </Fetching>
          <SliderButton
            direction="left"
            innerRef={sliderPrevButton}
            size="large"
            style={{ visibility: products ? 'visible' : 'hidden' }}
          />
          <SliderButton
            direction="right"
            innerRef={sliderNextButton}
            size="large"
            style={{ visibility: products ? 'visible' : 'hidden' }}
          />
        </div>
      </b2x.EqualHeight>
    </div>
  );
};
