import { b2x } from '@b2x/react/src';

export type TestNames = 'Simone' | 'Alessandro';

export type TestProps = Omit<b2x.TestProps, 'name'>;

export const Test = (props: TestProps) => (
  <b2x.Test {...props} customComponent={CustomTest} name="simone (props override)" />
);

// export const Test = (props: TestProps) => <p>Ciao simone (custom)</p>;

// const y = () => <b2x.Test name="simone" surname="asd" />;
// const z = () => <b2x.Test2 test={{ name: 'simone', surname: '' }} />;

// export const Test = (props: TestProps) => <b2x.Test {...props} customComponent={CustomTest} name="Simoneee" />;

const CustomTest = ({ name }: b2x.TestProps) => <p>Ciao {name} (custom)</p>;
