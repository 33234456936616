import './SliderA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { useAppContext } from '../AppContext';
import { SliderAContentSection } from '../contentTypes';
import { Slider } from '../Slider';
export interface SliderAProps extends SliderAContentSection {
  alignItems: 'left' | 'center' | 'right';
  container: 'fluid' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm';
  justifyContent: 'left' | 'center' | 'right';
}

export const SliderA = ({ alignItems, container, justifyContent, sliderList }: SliderAProps) => {
  const { getPagePath } = b2x.useAppStaticContext();
  const { headerHeight, topBarDesktopHeight, topBarMobileHeight, transparentHeader } = useAppContext();

  return (
    <>
      {sliderList && (
        <Slider
          className="SliderA absolute-content"
          loop
          pagination
          sliderAutoPlay={8000}
          slidesPerView={1}
          watchSlidesProgress
        >
          {sliderList.map((slide) => (
            <SwiperSlide key={slide.contentSectionId}>
              {(slideData) => (
                <>
                  <b2x.ConditionalWrapper
                    condition={slide.cta !== undefined}
                    wrapper={
                      <b2x.router.Link
                        to={
                          slide.cta?.to?.href
                            ? slide.cta.to.href
                            : slide.cta?.to?.code && getPagePath(slide.cta.to.code)
                        }
                      />
                    }
                  >
                    <div
                      className="slide-background"
                      style={{
                        height: `calc(100vh - ${
                          (!transparentHeader ? headerHeight ?? 0 : 0) +
                          (topBarDesktopHeight ?? 0) +
                          (topBarMobileHeight ?? 0)
                        }px)`,
                      }}
                    >
                      {b2x.isVideoAsBackgroundFromContentValid(slide.videoV2) ? (
                        <b2x.VideoAsBackgroundFromContentV1 {...slide.videoV2} />
                      ) : slide.video?.src && slide.video.src.length > 1 ? (
                        <b2x.DeprecatedVideoAsBackgroundFromContent {...slide.video} />
                      ) : (
                        <b2x.ImageAsBackgroundFromContentV1 {...slide.img} />
                      )}
                    </div>
                    <div
                      className={classnames(
                        'slide-content-wrapper d-flex',
                        `container-${container}`,
                        { 'text-start': slide.textAlignment === 'left' },
                        { 'text-center': slide.textAlignment === 'center' },
                        { 'text-end': slide.textAlignment === 'right' },
                        { 'justify-content-start': justifyContent === 'left' },
                        { 'justify-content-center': justifyContent === 'center' },
                        { 'justify-content-end': justifyContent === 'right' },
                        { 'align-items-start': alignItems === 'left' },
                        { 'align-items-center': alignItems === 'center' },
                        { 'align-items-end': alignItems === 'right' }
                      )}
                      style={{ paddingTop: headerHeight }}
                    >
                      <div className="slide-content">
                        <div className="slide-text">
                          <h3 className={`display-3 text-${slide.titleSubtitleVariant} fw-bold`}>{slide.title}</h3>
                          <p className={`xl-h5 text-${slide.titleSubtitleVariant}`}>{slide.subtitle}</p>
                        </div>
                        {slide.cta && slide.cta.label && (
                          <b2x.DeprecatedCta cta={slide.cta}>
                            <div className="slide-action mt-5">
                              <div className={`px-4 btn btn-${slide.cta.variant}`}>{slide.cta.label}</div>
                            </div>
                          </b2x.DeprecatedCta>
                        )}
                      </div>
                    </div>
                  </b2x.ConditionalWrapper>
                </>
              )}
            </SwiperSlide>
          ))}
        </Slider>
      )}
    </>
  );
};
