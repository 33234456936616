import './SizeGuide.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button, ButtonProps } from './Button';
import { SizeGuideContentType } from './contentTypes';
import { t } from './i18n/i18n';

export interface SizeGuideProps {
  button?: ButtonProps;
  className?: string;
  sizeChartContentCode: string;
}

export const SizeGuide = ({ button, className, sizeChartContentCode }: SizeGuideProps) => {
  const sizeChartContent = b2x.useContent<SizeGuideContentType>(sizeChartContentCode);

  const { showModal } = b2x.useModals();

  const handleButtonClick = React.useCallback(() => {
    sizeChartContent &&
      showModal({
        children: <SizeGuideModal sizeChartContent={sizeChartContent} />,
        fullscreen: 'lg',
        size: 'large',
        title: t('product.sizeGuide'),
      });
  }, [showModal, sizeChartContent]);

  return (
    <>
      {sizeChartContent && (
        <div className={classnames('Size-guide', className)}>
          <Button
            className={button?.className}
            iconStart={button?.iconStart}
            label={button?.label ? button.label : t('product.sizeGuide')}
            onClick={handleButtonClick}
            type="button"
            variant="blank"
          />
        </div>
      )}
    </>
  );
};

interface SizeGuideModalProps {
  className?: string;
  sizeChartContent: b2x.ContentApiDto<SizeGuideContentType>;
}

const SizeGuideModal = ({ className, sizeChartContent }: SizeGuideModalProps) => {
  return (
    <div className={classnames('Size-guide', className)}>
      <div className="d-flex justify-conente-center">
        {sizeChartContent.body.image && <b2x.NotResponsiveImageFromContent fluid {...sizeChartContent.body.image} />}
      </div>
      <div className="table-responsive lh-1">
        {sizeChartContent.body.table && b2x.formatHtml(sizeChartContent.body.table)}
      </div>
    </div>
  );
};
