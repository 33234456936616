/* eslint-disable react/forbid-elements */
import { b2x } from '@b2x/react/src';

import loading from './images/loading.svg';

export type LoadingProps = b2x.LoadingProps;

export const Loading = (props: LoadingProps) => {
  return (
    <b2x.Div textAlign={'center'}>
      <img alt="loading..." height={100} src={loading} width={100} />
    </b2x.Div>
  );
};
