import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Page } from './Page';

export const TestPage = () => {
  // const product = b2x.useProductById('360909', { populate: b2x.appConfig.api?.productPopulate });

  return (
    <Page>
      {/* <Container>
        <b2x.Row>
          <b2x.Col>
            <b2x.LoginFormHelper />
          </b2x.Col>
          <b2x.Col>
            <b2x.LoginForm2 />
          </b2x.Col>
        </b2x.Row>
      </Container>
      <hr /> */}
      {/* {product && (
        <Container>
          <b2x.Row>
            <b2x.Col>
              <b2x.AddToCartForm product={product} scope="product" />
            </b2x.Col>
            <b2x.Col>
              <b2x.AddToCartForm2 product={product} scope="product" />
            </b2x.Col>
          </b2x.Row>
        </Container>
      )} */}
      <Container>
        <b2x.CartEmailForm variant="whois" />
      </Container>
    </Page>
  );
};
