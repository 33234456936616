import { b2x } from '@b2x/react/src';

import { AmbassadorList } from '../AmbassadorList';
import { Container } from '../Container';
import { CrewAmbassadorsPageContentType } from '../contentTypes';
import { CrewHeader } from '../CrewHeader';
import { Page } from './Page';

export interface CrewAmbassadorsPageProps {}

export const CrewAmbassadorsPage = (props: CrewAmbassadorsPageProps) => {
  const page = b2x.usePage<CrewAmbassadorsPageContentType>();
  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="CrewAmbassadorsPage">
        <CrewHeader
          description={page?.content?.body.header?.description}
          image={page?.content?.body.header?.image}
          subtitle={page?.content?.body.header?.subtitle}
          title={page?.content?.body.header?.title}
        />
        <div className="mt-5 pt-5">
          <Container fluid="always">
            <AmbassadorList />
          </Container>
        </div>
      </div>
    </Page>
  );
};
