import './CartPage.scss';

import { b2x } from '@b2x/react/src';

import { t } from '../i18n/i18n';
import { PageHeader } from '../PageHeader';
import { Page } from './Page';

export type CartPageProps = b2x.CartPageProps;

export const CartPage = (props: CartPageProps) => {
  return (
    <Page greyBackground noPaddingTop>
      <div className="cart-page">
        <PageHeader small title={t('cart.title')} />
        <b2x.CartPage {...props} enableContinueShopping />
      </div>
    </Page>
  );
};
