import classnames from 'classnames';

import { appConfig } from './config';
import { DeprecatedCta } from './DeprecatedCta';
export interface WhatsAppClickToChatProps {
  background?: string;
  className?: string;
  color?: string;
  size?: number;
}

export const WhatsAppClickToChat = ({
  background = '#25D366',
  className,
  color = '#FFFFFF',
}: WhatsAppClickToChatProps) => {
  return (
    <>
      {appConfig.whatsAppClickToChat && (
        <DeprecatedCta
          className={classnames('whatsapp-click-to-chat', className)}
          cta={{ to: { href: `https://wa.me/+39${appConfig.whatsAppClickToChat}` } }}
          style={{ background: background }}
        >
          <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.4439 14.9322C18.8174 14.9473 19.2312 14.9649 19.6244 15.8367C19.8923 16.4316 20.3426 17.5392 20.7022 18.4239C20.9687 19.0794 21.1854 19.6125 21.2413 19.7239C21.3726 19.9857 21.4596 20.2921 21.285 20.641C21.2581 20.6946 21.2334 20.7452 21.2097 20.7934C21.0796 21.0591 20.9835 21.2553 20.761 21.514C20.6722 21.6173 20.5803 21.7288 20.4885 21.8401C20.3089 22.0581 20.1295 22.2757 19.9737 22.4311C19.7106 22.692 19.4367 22.9757 19.7431 23.4994C20.0496 24.0232 21.1023 25.7372 22.6618 27.1251C24.3404 28.619 25.798 29.2497 26.5359 29.569C26.6791 29.631 26.7953 29.6812 26.8806 29.7238C27.4057 29.9855 27.7113 29.9417 28.0175 29.5922C28.3237 29.2427 29.3279 28.0639 29.678 27.5398C30.0282 27.0158 30.3777 27.1026 30.8586 27.2775C31.3394 27.4524 33.9182 28.719 34.4433 28.981C34.5466 29.0325 34.6431 29.079 34.7325 29.122C35.0975 29.2977 35.344 29.4163 35.4492 29.5922C35.5802 29.8112 35.5799 30.8588 35.143 32.0815C34.7061 33.3042 32.5645 34.4836 31.6022 34.5708C31.5096 34.5792 31.4175 34.59 31.3228 34.6011C30.4336 34.7054 29.3116 34.8369 25.3068 33.2606C20.3741 31.3192 17.123 26.5059 16.4559 25.5182C16.4018 25.4382 16.3647 25.3832 16.3451 25.357L16.3388 25.3487C16.0548 24.9696 14.2031 22.4985 14.2031 19.9418C14.2031 17.5317 15.3898 16.2677 15.9362 15.6857C15.9739 15.6456 16.0085 15.6088 16.0394 15.575C16.52 15.0509 17.0884 14.9199 17.4383 14.9199L18.4439 14.9322Z"
              fill={color}
            />
            <path
              clipRule="evenodd"
              d="M3.7002 45.9234L6.67909 35.0679C4.83877 31.8834 3.8721 28.271 3.87655 24.5947C3.8814 13.0437 13.2995 3.64648 24.8729 3.64648C30.4891 3.64933 35.7606 5.83074 39.7252 9.79053C43.6897 13.7503 45.871 19.0137 45.869 24.6115C45.8639 36.1616 36.4444 45.5606 24.8726 45.5606H24.8635C21.3498 45.5591 17.8972 44.6793 14.8305 43.0103L3.7002 45.9234ZM24.8788 7.1856C15.2527 7.1856 7.42434 14.9961 7.42092 24.5964C7.41618 27.8746 8.3411 31.0872 10.0888 33.8629L10.504 34.5217L8.74072 40.9465L15.3457 39.218L15.9835 39.5951C18.6622 41.1818 21.7335 42.0209 24.8657 42.0226H24.8722C34.4909 42.0226 42.3193 34.2112 42.323 24.6103C42.3302 22.322 41.8826 20.055 41.0061 17.9405C40.1296 15.826 38.8415 13.906 37.2164 12.2918C35.6004 10.6687 33.6778 9.38165 31.5601 8.50517C29.4423 7.62869 27.1714 7.18018 24.8788 7.1856Z"
              fill={color ? color : '#FFFFFF'}
              fillRule="evenodd"
            />
          </svg>
        </DeprecatedCta>
      )}
    </>
  );
};
