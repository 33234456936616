import './Footer.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Accordion } from './Accordion';
import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { FooterContentType } from './contentTypes';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { ListAFromMenu } from './ListAFromMenu';
import { PopUpNewsletterModal } from './pages/PopupNewsletterModal';
import { SocialIcons } from './SocialIcons';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerBottomMenu = b2x.useMenu('MENU_BOTTOM_FOOTER');
  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');

  const { greyBackgroundPage, minimalFooter } = useAppContext();
  const { setFooterCopyrightRef, showAccountOffcanvas } = useAppStaticContext();

  const { showModal } = b2x.useModals();

  const { session } = b2x.useAppContext();

  const handleMobileNewsletterPopup = React.useCallback(() => {
    showModal({
      children: <PopUpNewsletterModal />,
      size: 'default',
      title: '',
    });
  }, [showModal]);

  return (
    <footer className="Footer mt-auto">
      {!minimalFooter && (
        <div className="bg-white">
          <div className="container-lg">
            {!greyBackgroundPage && <hr className="m-0 d-none d-lg-block" />}
            <div className="px-xl-5 py-lg-5">
              <div className="row">
                <div className="col-lg-4 p-0 px-lg-2 order-lg-2">
                  <div className="ps-lg-4 text-center text-lg-start">
                    <div className="footerNewsletter d-none d-lg-block">
                      <h6 className="fw-semi-bold">{t('misc.newsletterTitle')}</h6>
                      <div className="fw-light extra-small">
                        {b2x.formatHtml(footerContent?.body.newsletterSubtitle)}
                      </div>
                      <NewsletterForm className="mb-4" emailFieldStyle="inputGroup" source="website-footer" />
                    </div>
                    <div className="footerNewsletter d-flex d-block d-lg-none d-md-block justify-content-center align-items-center py-2 py-lg-0">
                      <Button onClick={handleMobileNewsletterPopup} variant="blank">
                        <Icon className="me-1" name="email" size={25} /> <span>{t('misc.newsletterSubscription')}</span>
                      </Button>
                    </div>
                    <div className="footerSocial d-flex d-lg-block justify-content-center align-items-center py-3 py-lg-0">
                      <h6 className="fw-semi-bold mb-0 mb-lg-2 pe-4">{t('misc.followUs')}</h6>
                      <SocialIcons />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <Accordion
                    accordionColClassName="p-0 px-lg-2"
                    columnLayoutFrom="lg"
                    id={'accordionExample'}
                    itemBodyClassName="pt-0"
                  >
                    {footerMenu?.children.map(
                      (column) =>
                        column.label && (
                          <b2x.AccordionItem
                            className={classnames({ 'd-lg-none': column.tags?.includes('mobile-only') })}
                            id={`accordionItem-${column.id}`}
                            key={column.code}
                            title={column.label}
                          >
                            {column.children.map((li) =>
                              li.code === 'SITE_CCN_AREA' ? (
                                session?.userLogged ? (
                                  li.children.map(
                                    (subLi) =>
                                      subLi.link && (
                                        <div key={subLi.code}>
                                          <b2x.router.Link
                                            className="text-reset text-decoration-none small d-block py-1"
                                            to={subLi.link}
                                          >
                                            {subLi.label}
                                          </b2x.router.Link>
                                        </div>
                                      )
                                  )
                                ) : (
                                  <div key={li.code}>
                                    <b2x.BlankButton className="small d-block py-1" onClick={showAccountOffcanvas}>
                                      {t('account.login')}
                                    </b2x.BlankButton>
                                  </div>
                                )
                              ) : (
                                li.link && (
                                  <div key={li.code}>
                                    {li.image ? (
                                      <b2x.router.Link className={'mt-2 d-inline-block'} to={li.link}>
                                        <b2x.Image {...li.image} fluid width={100} />
                                      </b2x.router.Link>
                                    ) : (
                                      <b2x.router.Link
                                        className="text-reset text-decoration-none small d-block py-1"
                                        to={li.link}
                                      >
                                        {li.label}
                                      </b2x.router.Link>
                                    )}
                                  </div>
                                )
                              )
                            )}
                          </b2x.AccordionItem>
                        )
                    )}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={classnames(greyBackgroundPage && minimalFooter ? 'bg-white' : 'bg-lighter')}
        ref={setFooterCopyrightRef}
      >
        <div className="container-lg">
          <div className="row align-items-center">
            <div className="col-lg-3 p-0 px-lg-2 order-lg-2">
              <div className="footerPaymentIcons py-lg-2">
                <b2x.PaymentIcons justifyContent="end" />
              </div>
            </div>
            <div className="col-lg-9 p-0 px-lg-2">
              <div className="footerCopyright py-lg-2">
                <div className="d-none d-lg-block text-dark mt-1 lh-1">
                  {footerBottomMenu && <ListAFromMenu menu={footerBottomMenu} textUppercase />}
                </div>
                {footerContent?.body.copyright && (
                  <small className="extra-small">{b2x.formatHtml(footerContent.body.copyright)}</small>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
