import { b2x } from '@b2x/react/src';

import { t } from '../i18n/i18n';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { NavigationListingToolbar } from '../NavigationListingToolbar';

export type SearchFormProps = b2x.SearchFormProps;

export const SearchForm = ({ searchResult, ...otherProps }: SearchFormProps) => {
  const { mainCategory } = b2x.useAppContext();

  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    searchResult: searchResult,
  });

  return (
    <>
      {MobileSearchFiltersOffcanvas}
      <b2x.SearchFormHelper searchResult={searchResult} submitOnChange {...otherProps}>
        {({ fieldsHelper, formik }) => {
          return (
            <>
              <NavigationListingToolbar
                currentFiltersItems={fieldsHelper.activeFilters.map((activeFilter) => ({
                  fromSimpleSearch: activeFilter.fromSimpleSearch,
                  label: activeFilter.filter.name ?? '',
                  onClick: activeFilter.handleClick,
                }))}
                formik={formik}
                navigationItems={[
                  {
                    content:
                      mainCategory !== undefined
                        ? // Se ho impostato una MainCategory, vado avanti normale
                          fieldsHelper.filters
                            .find((tree) => tree.filter.code === 'CATEGORIE')
                            ?.children.map((macroCategory) => ({
                              checkboxes: macroCategory.children.map((category) => ({
                                checkbox: {
                                  ...category.checkbox,
                                },
                                open:
                                  category.filter.checkType === 'CHECKED' ||
                                  category.filter.checkType === 'CHILD_CHECKED',
                                subCheckboxes: category.children.map((subCategory) => subCategory.checkbox),
                              })),
                              label: macroCategory.filter.name,
                            }))
                        : // Se invece non ho ancora impostato una MainCategory
                          fieldsHelper.filters
                            .filter((tree) => tree.filter.code === 'CATEGORIE')
                            .map((macroCategory) => ({
                              checkboxes: macroCategory.children.map((category) => ({
                                checkbox: {
                                  ...category.checkbox,
                                },
                                open: false,
                                subCheckboxes: category.children.map((subCategory) => subCategory.checkbox),
                              })),
                            })),
                    label: t('misc.categories'),
                  },
                  /*{
                      label: 'Collezione',
                    },*/
                  {
                    content: fieldsHelper.filters
                      .filter((tree) => tree.filter.code !== 'CATEGORIE')
                      .map((macroCategory) => ({
                        checkboxes: macroCategory.children.map((category) => ({
                          checkbox: {
                            ...category.checkbox,
                            onClick: () => {
                              formik.submitForm();
                            },
                          },
                          open: false,
                        })),
                        label: macroCategory.filter.name,
                      })),
                    label: t('misc.filterBy'),
                  },
                ]}
                showMobileSearchFiltersOffcanvas={showMobileSearchFiltersOffcanvas}
              />
            </>
          );
        }}
      </b2x.SearchFormHelper>
    </>
  );
};
