import './Offcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from './Button';

export type OffcanvasHeaderProps = b2x.OffcanvasHeaderProps;

export const OffcanvasHeader = (props: OffcanvasHeaderProps) => (
  <b2x.OffcanvasHeader {...props} customComponent={CustomOffcanvasHeader} />
);

const CustomOffcanvasHeader = ({ children, className, smallPadding, title }: OffcanvasHeaderProps) => {
  const { id } = b2x.useModalContext();
  return (
    <div className={classnames('offcanvas-header-wrapper py-3 pb-0', smallPadding ? 'px-3' : 'px-5')}>
      <div className={classnames('offcanvas-header p-0 pb-3 border-bottom', className)}>
        {(title || children) && (
          <h5 className="offcanvas-title text-uppercase h3" id={`${id}-label`}>
            {title}
            {children}
          </h5>
        )}
        <Button
          aria-label="Close"
          data-bs-dismiss="offcanvas"
          iconEnd={{ name: 'delete' }}
          type="button"
          variant="blank"
        />
      </div>
    </div>
  );
};
