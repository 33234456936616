import './SocialSharing.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Icon, IconSize } from './Icon';

export interface SocialSharingProps {}

export const SocialSharing = () => {
  const [openSocialSharing, setOpenSocialSharing] = React.useState(false);

  const handleClickOpenSharing = React.useCallback(() => {
    if (openSocialSharing) {
      setOpenSocialSharing(false);
    } else {
      setOpenSocialSharing(true);
    }
  }, [openSocialSharing]);

  const close = React.useCallback(() => {
    setOpenSocialSharing(false);
  }, []);

  const ref = React.useRef<HTMLDivElement>(null);

  b2x.useOutsideClickHandler(ref, close);
  b2x.useKeyPress('Escape', close);

  const { showModal } = b2x.useModals();

  const handleButtonClick = React.useCallback(() => {
    showModal({
      centered: true,
      children: <SocialSharingContent className="justify-content-center gap-5" iconSize={25} />,
      title: t('product.share'),
    });
  }, [showModal]);

  return (
    <div className="SocialSharing" ref={ref}>
      <div className="social-sharing-container">
        <div className="d-none d-md-block">
          <Button
            className="fw-light text-decoration-none"
            onClick={handleClickOpenSharing}
            type="button"
            variant="blank"
          >
            {t('product.share')}
            <span className="ms-2 text-white bg-black rounded-circle">
              <Icon name={'sharing'} size={20} />
            </span>
          </Button>
          <div className={classNames('social-sharing-group bg-lighter', !openSocialSharing && 'd-none')}>
            <SocialSharingContent className="justify-content-center gap-3" iconSize={20} />
          </div>
        </div>
        <div className="d-md-none">
          <Button className="fw-light text-decoration-none" onClick={handleButtonClick} type="button" variant="blank">
            <span className="text-white bg-black rounded-circle">
              <Icon name={'sharing'} size={20} />
            </span>
            {t('product.share')}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface SocialSharingContentProps extends SocialSharingProps {
  className?: string;
  iconSize: IconSize;
}

const SocialSharingContent = ({ className, iconSize }: SocialSharingContentProps) => {
  const sharingUrl = window.location.href;
  return (
    <div className={classNames('d-flex', className)}>
      {/* eslint-disable-next-line react/forbid-elements */}
      <a
        className="text-black"
        href={`https://www.facebook.com/sharer/sharer.php?u=${sharingUrl}`}
        rel="noreferrer"
        target="_blank"
      >
        <Icon name="sharing-facebook" size={iconSize} />
      </a>

      {/* eslint-disable-next-line react/forbid-elements */}
      <a
        className="text-black"
        href={`https://twitter.com/intent/tweet?text=${sharingUrl}`}
        rel="noreferrer"
        target="_blank"
      >
        <Icon name="sharing-twitter" size={iconSize} />
      </a>

      {/* eslint-disable-next-line react/forbid-elements */}
      <a
        className="text-black"
        href={`https://api.whatsapp.com/send?text=%0a${sharingUrl}`}
        rel="noreferrer"
        target="_blank"
      >
        <Icon name="sharing-whatapp" size={iconSize} />
      </a>
    </div>
  );
};
