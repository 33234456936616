// Styles must use direct files imports
import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss/pagination'; // Pagination module
import 'swiper/scss/effect-fade';
import 'swiper/scss'; // core Swiper
import './Slider.scss'; // b2x customization

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
// Core modules imports are same as usual
import SwiperInterface, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
// Direct React component imports
import { Swiper } from 'swiper/react';

export interface SliderProps {
  autoHeight?: boolean;
  centeredSlides?: boolean;
  children?: React.ReactNode;
  className?: string;
  fade?: boolean;

  loop?: boolean;
  navigation?: boolean;
  onSwiper?(swiper: SwiperInterface): void;
  pagination?: boolean;
  responsive?: Partial<Record<SliderBreakpoint, SliderResponsiveProps>>;
  slideContentCentered?: boolean;
  sliderAutoPlay?: number;
  sliderNextEl?: React.RefObject<HTMLButtonElement> | string;
  sliderPrevEl?: React.RefObject<HTMLButtonElement> | string;
  slidesPerView: number | 'auto';
  spaceBetween?: number;
  vertical?: boolean;
  watchSlidesProgress?: boolean;
}
export type SliderBreakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type SliderResponsiveProps = Pick<SliderProps, 'slidesPerView'>;

export const Slider = ({
  autoHeight,
  centeredSlides,
  children,
  className,
  fade,
  loop,
  navigation,
  onSwiper,
  pagination,
  responsive,
  slideContentCentered,
  sliderAutoPlay,
  sliderNextEl,
  sliderPrevEl,
  slidesPerView,
  spaceBetween,
  vertical,
  watchSlidesProgress,
}: SliderProps): React.ReactElement => {
  const breakpoint = b2x.useBreakpoint();

  return (
    <Swiper
      autoHeight={autoHeight ? true : false}
      autoplay={sliderAutoPlay ? { delay: sliderAutoPlay } : false}
      /* breakpoints sembra essere buggato, se da browser fai ingrandisci/rimpicciolisci la finestra varie volte
         provocando un cambio di breakpoint, alla lunga si rallenta tutto.
      */
      // breakpoints={}
      centeredSlides={centeredSlides ? true : false}
      className={classnames(
        className,
        slidesPerView === 'auto' && 'auto-width',
        slideContentCentered && 'slide-content-centered'
      )}
      direction={vertical ? 'vertical' : 'horizontal'}
      effect={fade ? 'fade' : 'slide'}
      fadeEffect={{ crossFade: true }}
      loop={loop ? true : false}
      modules={[Pagination, Navigation, Autoplay, EffectFade]}
      navigation={
        navigation
          ? sliderNextEl !== undefined && sliderPrevEl !== undefined
            ? {
                nextEl: typeof sliderNextEl === 'string' ? sliderNextEl : sliderNextEl.current,
                prevEl: typeof sliderPrevEl === 'string' ? sliderPrevEl : sliderPrevEl.current,
              }
            : true
          : false
      }
      onSwiper={onSwiper}
      pagination={pagination ? { clickable: true } : false}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween ? spaceBetween : 0}
      watchSlidesProgress={watchSlidesProgress}
      {...(breakpoint === 'sm' && { ...responsive?.sm })}
      {...(breakpoint === 'md' && { ...responsive?.sm, ...responsive?.md })}
      {...(breakpoint === 'lg' && { ...responsive?.sm, ...responsive?.md, ...responsive?.lg })}
      {...(breakpoint === 'xl' && {
        ...responsive?.sm,
        ...responsive?.md,
        ...responsive?.lg,
        ...responsive?.xl,
      })}
      {...(breakpoint === 'xxl' && {
        ...responsive?.sm,
        ...responsive?.md,
        ...responsive?.lg,
        ...responsive?.xl,
        ...responsive?.xxl,
      })}
    >
      {children}
    </Swiper>
  );
};
