import './NewsletterForm.scss';

import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
import { ResourceKeys, t } from '../i18n/i18n';

type AdditionalPropertiesFormValues = {
  topics: Array<b2x.formikString>;
};

type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{
  topics: b2x.yup.ArraySchema<b2x.yup.StringSchema>;
}>;

export type NewsletterFormProps = Omit<
  b2x.NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'validationSchemaSelector'
>;

export const NewsletterForm = ({ emailFieldStyle = 'textInput', ...otherProps }: NewsletterFormProps) => (
  <b2x.NewsletterFormHelper<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>
    additionalProperties={{
      formValues: {
        NEWSLETTER_TOPICS_OF_INTEREST: (values) =>
          values.additionalProperties ? values.additionalProperties.topics.join(',') : '',
      },
      initialValues: { topics: [] },
      validationSchema: b2x.yup.object({ topics: b2x.yup.array().of(b2x.yup.string()) }),
    }}
    emailFieldStyle={emailFieldStyle}
    validationSchemaSelector={{ name: false, surname: false }}
    {...otherProps}
  >
    {({ fieldsHelper }) => (
      <>
        {emailFieldStyle === 'textInput' && (
          <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
            <b2x.TextInput {...fieldsHelper.email.textInput} />
          </b2x.FormGroup>
        )}
        {emailFieldStyle === 'inputGroup' && (
          <b2x.FormGroup {...fieldsHelper.email.formGroup} className="custom-icon-btn-group" label={undefined}>
            <b2x.InputGroup {...fieldsHelper.email.inputGroup}>
              <b2x.TextInput {...fieldsHelper.email.textInput} />
              <Button
                {...fieldsHelper.buttons.submit}
                className="bg-lighter"
                iconEnd={{ name: 'small-arrow-right' }}
                label={undefined}
                size="small"
                variant="blank"
              />
            </b2x.InputGroup>
          </b2x.FormGroup>
        )}
        <b2x.FormGroup names={[]}>
          {['woman', 'man', 'young', 'horse'].map((topic) => (
            <b2x.Checkbox
              id={topic}
              inline
              key={topic}
              label={t(`form.newsletterForm.topics.${topic}` as ResourceKeys)}
              labelClassName="small"
              name="additionalProperties.topics"
              value={topic}
            />
          ))}
        </b2x.FormGroup>
        <b2x.FormGroup {...fieldsHelper.newsletterConsent.formGroup} label={undefined}>
          <b2x.Checkbox {...fieldsHelper.newsletterConsent.checkbox} labelClassName="text-dark extra-small" />
        </b2x.FormGroup>
        {emailFieldStyle === 'textInput' && (
          <div className="d-grid">
            <b2x.Button {...fieldsHelper.buttons.submit} />
          </div>
        )}
      </>
    )}
  </b2x.NewsletterFormHelper>
);
