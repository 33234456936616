import moment from 'moment';
import React from 'react';

import { Container } from '../Container';
import { useCountdown } from '../useCountdown';
import { prettyStringify } from '../util';

export interface CountdownProps {}

export const Countdown = (props: CountdownProps) => {
  const date = React.useMemo(() => moment().clone().add(10, 'seconds').toDate(), []);

  const handleComplete = React.useCallback(() => {
    console.log('complete!');
  }, []);

  const countdown = useCountdown(date, {
    onComplete: handleComplete,
  });

  return (
    <Container>
      {prettyStringify({
        countdown,
        date,
        now: new Date(),
      })}
    </Container>
  );
};
