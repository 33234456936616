import { TestA } from './TestA';
import { TestB } from './TestB';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface TestProps {
  name: string;
  surname?: string;
}

const Test = ({ name }: TestProps) => {
  return <p>Ciao {name} (default)</p>;
};

export type TestVariants = 'A' | 'B';

const TestController = (props: PropsWithCustomComponent<TestProps>) => (
  <VariantsController<TestProps, TestVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: TestA, B: TestB },
      defaultComponent: Test,
      name: 'Test',
    }}
  />
);
export { TestController as Test };
