export const en = {
  alert: {
    WAREHOUSESKU_NOT_AVAILABLE: 'Ops, this product is not available, remove it to continue',
  },
  cart: {
    summary: {
      totalDiscount: 'You are saving',
    },
  },
  checkout: {
    edit: 'Edit',
  },
  contest: {
    registration: {
      cta: {
        toCollection: 'Scopri la collezione',
      },
      customerTitle: 'ciao {{name}} {{surname}}',
      emailMessage: '',
      guestTitle: 'Sei già registrato?',
      login: 'Login',
      messages: {
        alredyRegistered: "L'iscrizione al contest risulta essere già effettuata",
        checkEmailNotification:
          'Controlla nella tua casella di posta, ti abbiamo inviato una email di conferma iscrizione.',
        emailNotification: 'Riceverai a breve una email di conferma.',
        registrationSuccess: 'Grazie per esserti iscritto!',
      },
    },
    regulation: 'Leggi il regolamento',
    subscribe: 'iscriviti',
    subscribeLogin: 'iscriviti/login',
  },
  footer: {
    giftCard: {
      title: 'Gift card Equestro',
    },
  },
  form: {
    contestForm: {
      adult: {
        adult: 'Maggiorenne',
        minor: 'Minorenne',
      },
      disclaimer: {
        label: 'Carica la liberatoria',
      },
      disclaimerText:
        "Scarica qui la liberatoria ed effettua l'upload del documento compilato e firmato dal genitore o tutore legale.",
      file: {
        label: 'Carica il video (formato mp4, peso massimo 20MB)',
      },
      instagram: {
        label: 'Instagram Nickname',
        placeholder: '@profiloInstagram',
      },
      photo: {
        label: 'Carica le foto (formato jpeg; peso max 1mb)',
        placeholder: {
          liveIt: 'Live it',
          rideIt: 'Ride it',
        },
      },
      regulation: 'Dichiaro di aver letto ed accettato il regolamento del contest',
      tiktok: {
        label: 'Tiktok Nickname',
        placeholder: '@profiloTikTok',
      },
    },
    newsletterForm: {
      topics: {
        horse: 'Horse',
        label: 'Topics',
        man: 'Man',
        woman: 'Woman',
        young: 'Young rider',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telephone' },
    },
  },
  header: {
    sendTo: 'Send to {{country}} | {{locale}}',
  },
  i18nTest: '',
  misc: {
    newsletterSubscription: 'Sign up and get 10% discount',
    storeName: 'Equestro',
  },
  product: {
    colorsCount: '',
    colorsCount_one: '{{count}} color',
    colorsCount_other: '{{count}} colors',
  },
  welcome: 'Welcome to Equestro',
};
