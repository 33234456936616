import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { CustomRadio } from './CustomRadio';
import { t } from './i18n/i18n';
import { SizeGuide } from './SizeGuide';
import { Slider, SliderBreakpoint, SliderResponsiveProps } from './Slider';
import { SliderButton } from './SliderButton';

export interface SkuAttributeFormGroupProps {
  attribute: {
    title: string;
    value?: string;
  };
  className?: string;
  formGroup: b2x.FormGroupProps;
  index: number;
  radios: Array<{ radio: b2x.RadioProps; skus: Array<b2x.SkuApiDto> }>;
  sizeChartContentCode?: string;
  sliderResponsive: Partial<Record<SliderBreakpoint, SliderResponsiveProps>>;
  slidesPerView: number | 'auto';
  spaceBetween?: number;
}

export const SkuAttributeFormGroup = ({
  attribute,
  className,
  formGroup,
  index,
  radios,
  sizeChartContentCode,
  sliderResponsive,
  slidesPerView,
  spaceBetween,
}: SkuAttributeFormGroupProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const radioSquare = React.useMemo<boolean>(
    () =>
      radios.some(
        (element) => typeof element.radio.label === 'string' && element.radio.label && element.radio.label.length > 3
      ),
    [radios]
  );

  return (
    <b2x.FormGroup {...formGroup} className={classNames(className, 'mb-2 mb-md-3')} label={undefined} noMarginBottom>
      {sizeChartContentCode && index === 0 && (
        <SizeGuide
          button={{
            className: 'text-dark fw-medium btn-sm text-decoration-none',
            iconStart: { name: 'size', size: 25 },
          }}
          className="d-none d-md-block"
          sizeChartContentCode={sizeChartContentCode}
        />
      )}
      <div className="form-label small fw-light mb-2">
        <span className="fw-normal">{attribute.title}:</span>&nbsp;
        {attribute.value ?? t('product.selectSize')}
      </div>

      <div className="d-flex">
        <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
        <Slider
          navigation
          responsive={sliderResponsive}
          sliderNextEl={sliderNextButton}
          sliderPrevEl={sliderPrevButton}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
        >
          {radios.map(({ radio }) => (
            <SwiperSlide key={radio.id}>
              <b2x.Radio {...radio} inline>
                <CustomRadio isSquare={radioSquare} label={radio.label as string} />
              </b2x.Radio>
            </SwiperSlide>
          ))}
        </Slider>
        <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
      </div>
    </b2x.FormGroup>
  );
};
