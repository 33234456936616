import React from 'react';

import { useStable } from './util';

export interface UseCountdownOptions {
  onComplete?(): void;
  stopAtZero?: boolean;
}

export interface UseCountdownResult {
  completed: boolean;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const useCountdown = (date?: Date, options?: UseCountdownOptions): UseCountdownResult | undefined => {
  const [difference, setDifference] = React.useState<number>();

  React.useEffect(() => {
    if (date) {
      setDifference(date.getTime() - new Date().getTime());
    }
  }, [date]);

  const { stopAtZero = true } = options ?? {};
  const { onComplete } = useStable(options ?? {});

  const result = React.useMemo(() => {
    if (difference !== undefined) {
      let _days = Math.floor(difference / (1000 * 60 * 60 * 24));
      let _hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let _minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      let _seconds = Math.floor((difference % (1000 * 60)) / 1000);

      const _completed = difference <= 1000;

      if (stopAtZero && _completed) {
        _days = 0;
        _hours = 0;
        _minutes = 0;
        _seconds = 0;
        setDifference(0);
      }
      return { completed: _completed, days: _days, hours: _hours, minutes: _minutes, seconds: _seconds };
    } else {
      return undefined;
    }
  }, [difference, stopAtZero]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (date) {
        setDifference(date.getTime() - new Date().getTime());
      }
    }, 1000);

    if (stopAtZero && result?.completed) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [date, result?.completed, stopAtZero]);

  React.useEffect(() => {
    if (result?.completed) {
      onComplete && onComplete();
    }
  }, [onComplete, result?.completed]);

  return result;
};
