import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { Page } from './Page';

export interface ForgotPasswordPageProps {}

export const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  return (
    <Page greyBackground>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-5">
            <Box>
              <div className="mb-4 ">
                <h3 className="text-uppercase text-center mb-3">Password dimenticata?</h3>
                <span className="fw-light">
                  Niente paura! inserisci l&apos;indirizzo e-mail utilizzato al momento della registrazione al sito, le
                  istruzioni per cambiarla ti saranno inviate automaticamente all&apos;indirizzo indicato.
                </span>
              </div>
              <b2x.ForgotPasswordFormHelper className="mb-4">
                {({ fieldsHelper }) => (
                  <>
                    <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
                      <b2x.TextInput {...fieldsHelper.email.textInput} />
                    </b2x.FormGroup>
                    <b2x.Button {...fieldsHelper.buttons.submit} />
                  </>
                )}
              </b2x.ForgotPasswordFormHelper>
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
