import './Section.scss'; // b2x customization

import classnames from 'classnames';

export interface SectionProprs {
  background?: 'white' | 'primary' | 'secondary' | 'lighter';
  children?: React.ReactNode;
  className?: string;
  container?: 'fluid' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm';
  containerClassName?: string;
  id?: string;
  innerRef?: React.RefObject<HTMLElement>;
  withDots?: boolean;
}

export const Section = ({
  background,
  children,
  className,
  container,
  containerClassName,
  id,
  innerRef,
  withDots,
}: SectionProprs): React.ReactElement => {
  const sectionBackground = background && `bg-${background}`;

  return (
    <section
      className={classnames('Section', sectionBackground, className, withDots && 'section-with-dot')}
      id={id}
      ref={innerRef}
    >
      {container ? (
        <div className={classnames(`container-${container}`, containerClassName)}>{children}</div>
      ) : (
        children
      )}
    </section>
  );
};
