import { Page } from './Page';

export interface MockPageProps {}

export const MockPage = (props: MockPageProps) => {
  return (
    <Page thingsToLoadBeforePageReady={[]}>
      <div className="container">
        <div className="py-5 text-center">
          <h3>Pagina bassa per testare lo sticky Footer</h3>
        </div>
      </div>
    </Page>
  );
};
