import { FloatingActionButton } from './FloatingActionButton';
import { WhatsAppClickToChat } from './WhatsAppClickToChat';

export interface WhatsAppClickToChatFABButtonProps {
  background?: string;
  className?: string;
  color?: string;
  size?: number;
}

export const WhatsAppClickToChatFABButton = ({ background, color }: WhatsAppClickToChatFABButtonProps) => {
  return (
    <FloatingActionButton>
      <WhatsAppClickToChat background={background} color={color} />
    </FloatingActionButton>
  );
};
