import { Div } from './HTMLElement';
import { t } from './i18n/i18n';

export interface CountdownProps {
  blockClassNames?: string;
  blockStyle?: React.CSSProperties;
  classNames?: string;
  days: number;
  hours: number;
  labelClassNames?: string;
  labelStyle?: React.CSSProperties;
  minutes: number;
  seconds: number;
  style?: React.CSSProperties;
  valueClassNames?: string;
  valueStyle?: React.CSSProperties;
}

export const Countdown = ({ classNames, days, hours, minutes, seconds, style, ...otherProps }: CountdownProps) => {
  return (
    <Div className={classNames} style={style}>
      <Block {...otherProps} label={t('misc.time.days', { count: days })} value={days} />
      <Block {...otherProps} label={t('misc.time.hours', { count: hours })} value={hours} />
      <Block {...otherProps} label={t('misc.time.minutes', { count: minutes })} value={minutes} />
      <Block {...otherProps} label={t('misc.time.seconds', { count: seconds })} value={seconds} />
    </Div>
  );
};

interface BlockProps {
  blockClassNames?: string;
  blockStyle?: React.CSSProperties;
  label: string;
  labelClassNames?: string;
  labelStyle?: React.CSSProperties;
  value: number;
  valueClassNames?: string;
  valueStyle?: React.CSSProperties;
}

const Block = ({
  blockClassNames,
  blockStyle,
  label,
  labelClassNames,
  labelStyle,
  value,
  valueClassNames,
  valueStyle,
}: BlockProps) => (
  <Div className={blockClassNames} style={blockStyle}>
    <Div className={valueClassNames} style={valueStyle}>
      {value}
    </Div>
    <Div className={labelClassNames} style={labelStyle}>
      {label}
    </Div>
  </Div>
);
