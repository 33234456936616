import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { CrewEventsPageContentType } from '../contentTypes';
import { CrewHeader } from '../CrewHeader';
import { EventList } from '../EventList';
import { Page } from './Page';

export interface CrewEventsPageProps {}

export const CrewEventsPage = (props: CrewEventsPageProps) => {
  const page = b2x.usePage<CrewEventsPageContentType>();
  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="CrewEventsPage">
        <CrewHeader
          description={page?.content?.body.header?.description}
          image={page?.content?.body.header?.image}
          subtitle={page?.content?.body.header?.subtitle}
          title={page?.content?.body.header?.title}
        />
        <div className="mt-5 pt-5">
          <Container>
            <EventList />
          </Container>
        </div>
      </div>
    </Page>
  );
};
