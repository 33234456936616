import './LoadingOverlay.scss';

import { b2x } from '@b2x/react/src';

import loading from './images/loading.svg';

export type LoadingOverlayProps = b2x.LoadingOverlayProps;

export const LoadingOverlay = (props: LoadingOverlayProps) => (
  <b2x.LoadingOverlay {...props} customComponent={CustomLoadingOverlay} />
);

const CustomLoadingOverlay = (props: b2x.LoadingOverlayProps) => (
  <div className="LoadingOverlay">
    <div className="position-absolute top-50 start-50 translate-middle text-white">
      {/* eslint-disable-next-line react/forbid-elements */}
      <img alt="loading..." src={loading} />
    </div>
  </div>
);
